<template>
    <div>
        <!-- Iframe para mostrar el PDF -->
        <iframe ref="pdfFrame" style="width: 100%; height: 100vh; border: none;"></iframe>

        <!-- Contenido del recibo envuelto en un div y oculto para captura -->
        <page ref="receiptContent">
            <section class="invoice">
                <div v-if="config.reportelogotermicoventa === 'ACTIVADO'">
                    <center><img :src="`data:image/jpg;base64,${reporte.imagenBase64}`" alt="logo" width="120"></center><br>
                </div>
                <!-- Información de la Empresa -->
                <div class="text-center">
                    <span class="text-16 text-dark text-bold">{{ reporte.empresa }}</span><br>
                    <span class="text-11 text-dark">{{ reporte.direccion }}</span><br>
                    <span class="text-11 text-dark">Tel: {{ reporte.telefono }}</span><br>
                    <span class="text-11 text-dark">{{ reporte.ubicacion }}</span><br>
                    <!-- Documento -->
                    <span class="text-14 text-dark text-bold-600">{{ reporte.documento }}</span>
                    <!-- Estado y Tipo de Venta -->
                    <span class="text-11 text-dark">{{ reporte.estado }}</span><br>
                    <span class="text-11 text-dark">{{ reporte.tipodeventa }}</span><br>
                    <!-- Información de la Venta -->
                    <span class="text-12 text-dark">Fecha: {{ reporte.fechaventa }} {{ reporte.horaventa }}</span><br>
                    <span class="text-14 text-dark text-bold-600">Nota No: <span>{{ reporte.nroventa }}</span></span>
                </div>

                <div class="text-start">
                    <span class="text-12 text-dark text-bold text-bold-600">Nombre/Razón Social:</span> <span class="text-12 text-dark text-bold-600"> {{ reporte.clientenombre }}</span><br>
                    <span class="text-12 text-dark text-bold text-bold-600">NIT/CI/CEX:</span><span class="text-12 text-dark text-bold-600"> {{ reporte.nitci }}</span><br>
                    <span v-if="reporte.observacion" class="text-11 text-dark text-bold text-bold-600">Observacion:</span><span class="text-12 text-dark text-bold-600"> {{ reporte.observacion }}</span><br>
                </div>
                <!-- Tabla de Productos -->
                <div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">CANT</th>
                                <th>DETALLE</th>
                                <th class="text-center">P.U</th>
                                <th class="text-center">DESC</th>
                                <th class="text-center">SUBTOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in reporte.detalle" :key="index">
                                <td class="text-center">{{ item.cantidad }}</td>
                                <td> {{ item.nombre }} {{ item.medida }}</td>
                                <td class="text-center">{{ item.precioVenta }}</td>
                                <td class="text-center">{{ item.descuento }}</td>
                                <td>{{ item.subtotal }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="4">Subtotal ({{ reporte.simbolo }})</th>
                                <th>{{ reporte.montototal }}</th>
                            </tr>
                            <tr>
                                <th colspan="4">Descuento ({{ reporte.simbolo }})</th>
                                <th>{{ reporte.descuento }}</th>
                            </tr>
                            <tr>
                                <th colspan="4">Total ({{ reporte.simbolo }})</th>
                                <th>{{ reporte.montocancelado }}</th>
                            </tr>
                            <tr>
                                <th colspan="4">Efectivo Entregado ({{ reporte.simbolo }})</th>
                                <th>{{ reporte.efectivo }}</th>
                            </tr>
                            <tr>
                                <th colspan="4">Cambio ({{ reporte.simbolo }})</th>
                                <th>{{ reporte.cambio }}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <span class="text-dark text-bold text-12">Son: </span><span class="text-dark text-12"> {{ reporte.montoliteral }}</span>
                </div>
                <!-- Información Adicional -->
                <div class="text-center">
                    <span class="text-dark text-12 text-bold-600">Usuario: {{ reporte.usuario }}</span><br>
                    <span class="text-dark text-13 text-bold">{{ reporte.almacen }}</span><br>
                    <span class="text-dark text-12">Reporte impreso por www.efectivo.io <br> Fecha Reporte {{ reporte.fechareporte }}</span><br>
                    <span class="text-dark text-12">GRACIAS POR SU PREFERENCIA</span>
                </div>
            </section>
        </page>
        <!-- Spinner de Carga -->
        <div v-if="spinnerloading" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 1); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as utils from '@/utils/utils'
    import jsPDF from 'jspdf'
    import html2canvas from 'html2canvas'

    export default {
        props: {
            ventaID: { type: String, required: true }
        },
        data () {
            return {
                spinnerloading: false
            }
        }, 
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('venta', ['reporte']),
            ...mapGetters('configuracion', ['config'])
        },
        methods: {
            ...mapActions('venta', ['getReporteVenta']),
            imprimir () {
                this.spinnerloading = true
                this.getReporteVenta(this.ventaID)
                    .then(response => {
                        this.generatePDF()
                            .then(() => {
                                this.spinnerloading = false
                                if (this.isMobile()) {
                                    this.downloadPDF()
                                } else {
                                    this.showPDF()
                                }
                            })
                    }).catch(error => {
                        utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                    })
            },
            async generatePDF () {
                const receiptContent = this.$refs.receiptContent
                const canvas = await html2canvas(receiptContent, { 
                    scale: 3, 
                    useCORS: true,
                    logging: false, 
                    allowTaint: true, 
                    letterRendering: true, 
                    backgroundColor: null, 
                    dpi: 80 
                })
                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPDF({ // eslint-disable-line new-cap
                    orientation: 'portrait',
                    unit: 'mm',
                    format: [82, canvas.height * 82 / canvas.width] 
                })
                const imgProps = pdf.getImageProperties(imgData)
                const pdfWidth = pdf.internal.pageSize.getWidth()
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
                this.pdfDataUri = pdf.output('bloburi')
                this.pdfBlob = pdf.output('blob')
            },
            isMobile () {
                return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
            },
            showPDF () {
                const receiptContent = this.$refs.receiptContent
                const pdfFrame = this.$refs.pdfFrame
                receiptContent.style.display = 'none'
                pdfFrame.src = this.pdfDataUri
                setTimeout(() => {
                    pdfFrame.contentWindow.print()
                }, 300)
            },
            downloadPDF () {
                const file = `venta-termica-${this.ventaID}.pdf`
                const link = document.createElement('a')
                link.href = URL.createObjectURL(this.pdfBlob)
                link.download = file
                link.click()
                setTimeout(() => {
                    window.close()
                }, 1000)
            }
        },
        mounted () {
            this.imprimir()
        }
    }
</script>

<style>
@page {
  size: 82mm;
  size: portrait;
}

body.receipt .sheet {
  width: 82mm;
  height: auto;
}

@media print {
  body.receipt {
    width: 82mm;
  }
}

body {
  counter-reset: chapternum figurenum;
  line-height: 1;
  height: 100%;
  color: #000000;
  background: rgb(204,204,204);
  font-family: Arial, sans-serif; /* Aquí se agrega la fuente Arial */
}

page {
  width: 8.2cm;
  height: auto;
  background: white;
  display: block;
  margin: 0 auto;
}

@media print {
  body, page {
    margin: 0;
    box-shadow: 0;
  }
}

a {
  color: black;
}

.invoice {
  padding: 5px;
}

.text-center {
  text-align: center;
}

.text-start {
  margin-top: 5px;
  text-align: left !important;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  padding: 3px;
  font-weight: bold;
  font-size: 10px;
}

.table td {
  padding: 3px;
  font-size: 11px;
  font-weight: 600;
}

.table tfoot th, .table tfoot td {
  border-top: 1px solid black;
  font-weight: bold;
  font-size: 11px;
}

.text-dark {
  color: black;
}

.text-bold {
  font-weight: 700;
}

.text-bold-600 {
  font-weight: 600;
}

.text-10 {
  font-size: 10px;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}
</style>
